import { css, SerializedStyles } from "@emotion/react"
import { breakpoints, media } from "."
import { colours } from "./variables/colour/Colour"

// https://www.nomensa.com/blog/2017/how-improve-web-accessibility-hiding-elements
const invisible = `
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

const focused = `
  outline: none;
  box-shadow: 0 0 0 3px ${colours.callout.dark};
`

const error = `
  outline: none;
  box-shadow: 0 0 0 2px ${colours.destructive.main};
`

const setBackgroundImage = (background: string, fallback: string): string => `
  background-image: url("${background}");
  background-image: url("${background}"), ${fallback};
`

interface Backgrounds {
  fallbackColor: string
  mobileFallbackGradient: string
  mobileBackgroundImage: string
  mobileBackgroundImageRetina: string
  tabletFallbackGradient: string
  tabletBackgroundImage: string
  tabletBackgroundImageRetina: string
  desktopFallbackGradient: string
  desktopBackgroundImage: string
  desktopBackgroundImageRetina: string
}

const gradientBackground = (backgrounds: Backgrounds): SerializedStyles => css`
  // Fallback for when the image can't be loaded and the browser can't handle
  // gradients
  background-color: ${backgrounds.fallbackColor};
  background-repeat: no-repeat;
  background-size: cover;

  ${setBackgroundImage(
    backgrounds.mobileBackgroundImage,
    backgrounds.mobileFallbackGradient
  )}

  ${media.retinaScreen`
    ${setBackgroundImage(
      backgrounds.mobileBackgroundImageRetina,
      backgrounds.mobileFallbackGradient
    )}
  `}

  ${breakpoints.tablet`
    ${setBackgroundImage(
      backgrounds.tabletBackgroundImage,
      backgrounds.tabletFallbackGradient
    )}

    ${media.retinaScreen`
      ${setBackgroundImage(
        backgrounds.tabletBackgroundImageRetina,
        backgrounds.tabletFallbackGradient
      )}
    `}
  `}

  ${breakpoints.desktop`
    ${setBackgroundImage(
      backgrounds.desktopBackgroundImage,
      backgrounds.desktopFallbackGradient
    )}

    ${media.retinaScreen`
      ${setBackgroundImage(
        backgrounds.desktopBackgroundImageRetina,
        backgrounds.desktopFallbackGradient
      )}
    `}
  `}
`

export const mixins = {
  invisible,
  focused,
  error,
  gradientBackground,
}
