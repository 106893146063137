// what is the point of this?
//
// good question
//
// the idea is that by everything in the monorepo using habito-amplitude,
// we won't have different versions, and can keep everything up to date at
// once. Using mixed version causes subtle bugs and problems for Data Science,
// so hopefully this minor piece of indirection can be excused.

export * from "amplitude-js"

import Amplitude from "amplitude-js"
export default Amplitude
