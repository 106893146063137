import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import {
  AmplitudeLogWithSchema,
  amplitude,
  registerPageView,
  logWithSchema,
} from "../../utils/amplitude"

import { loadGoogleTagManager } from "@heyhabito/head-matter"

// remember that any JS inserted directly into script tags here
// will NOT be transpiled and so be careful only to use ES5 syntax
// ie, no `const` or object spread syntax

import "design-kit/src/assets/base-styles/Fonts.css"
import "design-kit/src/assets/base-styles/reset.css"
import { colours, fonts, horizontal, mixins, vertical } from "design-kit"

import { GlobalHabitoConfig } from "../../types/config"
import { useUser } from "../Shared/UserProvider"

declare global {
  interface Window {
    Intercom: (
      command: string,
      options?: Partial<Record<string, unknown>>
    ) => void
  }
}

export interface BaseProps {
  metaTitle: string
  metaDescription: string
  metaImage?: string
  noIndex: boolean
  canonicalUrl: string
  pageName: string
  intercom: boolean
  config: GlobalHabitoConfig
  head?: React.ReactNode
  amplitudePageLoadCallback?: (a: AmplitudeLogWithSchema) => void
  amplitudeBeforeUnloadCallback?: (a: AmplitudeLogWithSchema) => void
}

export const Base: React.FunctionComponent<BaseProps> = props => (
  <React.Fragment>
    <Head {...props} />
    {props.children}
  </React.Fragment>
)

/* eslint-disable no-useless-escape */
const Head: React.FC<BaseProps> = ({
  metaTitle,
  noIndex,
  canonicalUrl,
  metaDescription,
  metaImage,
  pageName,
  intercom,
  config,
  head,
  amplitudePageLoadCallback,
  amplitudeBeforeUnloadCallback,
}) => {
  const user = useUser()

  // Tell Intercom the identity of the currently signed-in user. In most cases,
  // Intercom already knows who the currently signed-in user is, because
  // Intercom persists user identity across page loads in most cases. But
  // sometimes it's useful to remind Intercom who the user is -- for instance,
  // if Intercom has forgotten who is the persisted user (e.g. Intercom's token
  // expired).
  //
  // If a user has been archived in Intercom, the following 'update' call
  // ensures they get un-archived upon their return to the marketing site, so
  // their chats correctly appear in the Intercom widget.
  //
  // We rely on 'window.Intercom' already existing based on the fact that the
  // 'Helmet' component below ensures the Intercom-loading script is included
  // in the initial HTML response to the browser navigation and therefore
  // executed before React kicks in and fetches the Habito user profile.
  React.useEffect(() => {
    if (user) {
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("update", {
          user_id: user.accountId,
          email: user.email,
          user_hash: user.intercomUserHash,
        })
      }
    }
  }, [user])

  useEffect(() => {
    // config amplitude and fire page view event
    if (typeof window !== "undefined") {
      amplitude.init(config.amplitudeApiId, undefined, {
        includeReferrer: true,
        includeUtm: true,
        saveParamsReferrerOncePerSession: false,
      })
      // fire page view event
      registerPageView(pageName, () => {
        // if we have an onPageLoad function, pass it the log function
        if (amplitudePageLoadCallback) {
          amplitudePageLoadCallback(logWithSchema)
        }

        // if we have a beforeUnload function, set it up
        if (amplitudeBeforeUnloadCallback) {
          window.addEventListener("beforeunload", () => {
            amplitudeBeforeUnloadCallback(logWithSchema)
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="format-detection" content="telephone=no" />
        {noIndex && <meta name="robots" content="noindex" />}
        <link href={canonicalUrl} rel="canonical" />

        <script type="text/javascript">
          {`window.HABITO_CONFIG = ${JSON.stringify(config)};`}
        </script>

        <link
          rel="preload"
          as="style"
          // TS thinks `onload` isn't a valid prop since it doesn't realise this
          // is going to be stringified into HTML
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onload="this.onload=null;this.rel='stylesheet';"
          href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css"
        />
        <style>
          {`
            /* When we have an Intercom banner shown at the top of the screen,
             * we want the main content & header to be pushed down.
             * This relative position adjusts where the absolutely positioned
             * header will sit.
             */
            #___gatsby {
              position: relative;
            }

            .cc-window {
              background-color: ${colours.white};
              border-radius: 4px;
              box-sizing: border-box;
              padding: ${vertical.m} ${horizontal.l};
            }

            .cc-layout {
              font-family: Roboto, serif;
              font-size: 14px;
            }

            .cc-layout ul {
              list-style: disc;
              margin-left: 24px;
            }

            .cc-link {
              display: inline;
              padding: 0;
            }

            /* design-kit::ctaStyles */
            .cc-btn:before {
              content: "";
              background: transparent;
              border-radius: 32px;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }

            /* design-kit::ctaStyles */
            .cc-btn:focus-visible {
              ${mixins.focused}
            }

            .cc-btn {
              /* design-kit::ctaStyles::baseCss */
              height: 48px;
              padding: 0 ${horizontal.l};
              border-radius: 32px;

              /* design-kit::ctaStyles::enabledCss */
              color: ${colours.white};
              background-color: ${colours.action.main};

              /* design-kit::ctaStyles */
              position: relative;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              text-decoration: none;
              white-space: nowrap;
              cursor: pointer;
              border: 0;

              /* design-kit::bodyStyles */
              ${fonts.Roobert};
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.25px;
            }

            /* design-kit::ctaStyles::enabledCss */
            .cc-btn:hover:before {
              background-color: ${colours.white};
              opacity: 0.2;
            }

            /* design-kit::ctaStyles::enabledCss */
            .cc-btn:active:before {
              background-color: ${colours.offBlack};
              opacity: 0.2;
            }

            /* design-kit::bodyStyles */
            .cc-layout .cc-body {
              ${fonts.Roobert};
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.25px;
            }

          `}
        </style>
        {head}

        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content={
            metaImage ||
            "https://habito-static-public.s3.eu-west-1.amazonaws.com/opengraph-image.png"
          }
        />
        <meta name="twitter:card" content="summary_large_image" />

        <script
          async
          src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js"
        />
        <script type="module" src="static/cookieconsent-config.js"></script>
        <script type="text/javascript">
          {`
            window.addEventListener("load", function() {
              if (typeof window['cookieconsent'] === 'undefined') return
              window.cookieconsent.initialise({
                layouts: {
                  "home-layout":
                    "<div class='cc-layout'>{{messagelink}}{{compliance}}</div>",
                },
                layout: "home-layout",
                palette: {
                  popup: {
                    background: "#ffffff",
                    text: "#252551",
                  },
                },
                content: {
                  message:
                    \`
                      <p class="cc-body">We use cookies to help us:</p>
                      <ul class="cc-list">
                        <li class="cc-body">keep our website running</li>
                        <li class="cc-body">identify you and remember your preferences</li>
                        <li class="cc-body">make our website more relevant to you</li>
                        <li class="cc-body">analyse how people use our website, so we can improve it</li>
                      </ul>
                      <p class="cc-body">
                        For more information,
                        <a aria-label='read our cookie notice' tabindex='0' class='cc-link' href='/cookies' target='_blank' rel='noopener noreferrer nofollow'
                          >read our cookie notice</a>.
                      </p>
                    \`,
                  link: "",
                  dismiss: "I understand",
                },
                position: "bottom-left",
              })
            })
          `}
        </script>

        <script type="text/javascript">
          {`
      // Various analytics things used in the site
      window.dataLayer = [{ doubleClickId: window.HABITO_CONFIG.doubleClickId }];

      ${loadGoogleTagManager(config.gtmAppId)}
      `}
        </script>

        {intercom && (
          <script type="text/javascript">
            {`
        // intercom globals
        window.intercomSettings = { app_id: window.HABITO_CONFIG.intercomAppId };

        (function() {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
              ic("reattach_activator");
              ic("update", w.intercomSettings);
            } else {
              var d = document;
              var i = function() {
                i.c(arguments);
              };
              i.q = [];
              i.c = function(args) {
                i.q.push(args);
              };
              w.Intercom = i;
              function l() {
                var s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = "https://widget.intercom.io/widget/" + window.HABITO_CONFIG.intercomAppId;
                var x = d.getElementsByTagName("script")[0];
                x.parentNode.insertBefore(s, x);
              }
              if (w.attachEvent) {
                w.attachEvent("onload", l);
              } else {
                w.addEventListener("load", l, false);
              }
            }
          }
        )();
        `}
          </script>
        )}

        <body data-page-name={pageName} />

        <script>
          {`
            /*
              This code is here instead of gatsby-browser.js because in dev mode
              the Gatsby API functions don't wait for the "final paint" of the
              DOM, so the scrolling behaviour wouldn't occur.

              Note that because this code is here, it will not be transpiled,
              and so it must be written in an ES5-compatible manner.
            */

            // Scrolls to the element that the hash refers if it exists
            function scrollToHash() {
              if (window.location.hash) {
                window.location.href = window.location.hash
              }
            }

            // Waiting for the callstack to finish
            setTimeout(scrollToHash)
          `}
        </script>
      </Helmet>
    </React.Fragment>
  )
}

export default Base
