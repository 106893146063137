import { css } from "@emotion/react"
import { CSSInterpolation } from "@emotion/css"

type FuncType = (
  template: TemplateStringsArray,
  ...args: Array<CSSInterpolation>
) => string

interface ExtendedFuncType extends FuncType {
  query: string
}

const getMediaForQuery = (query: string): ExtendedFuncType => {
  const func: ExtendedFuncType = (template, ...args) => `
    @media ${query} {
      ${css(template, ...args).styles};
    }
  `

  func.query = query

  return func
}

/**
 * By explicitly looking for `no-preference` we are checking that A) the user's
 * browser supports the `prefers-reduced-motion` query and B) they haven't
 * chosen to set it to `reduce`. So users are "opting-in" to animation.
 */
const allowsMotion = getMediaForQuery("(prefers-reduced-motion: no-preference)")

const retinaScreen = getMediaForQuery(`
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-resolution: 2dppx)
`)

export const media = {
  allowsMotion,
  retinaScreen,
}
