import { AmplitudeEvent } from "@heyhabito/amplitude/src/types"

// Hello. These are the functions for sending Amplitude events
// If possible, you should use the amplitudePageLoadCallback and
// amplitudeBeforeUnloadCallback on the Base component to take care of
// things, but if not, you probably want to use logWithSchema
// as it uses the AmplitudeEvent type to make sure we're not just sending
// nonsense

// use this internally so that we don't actually use the Amplitude library at
// build time and break everything
export const amplitude =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  typeof window !== "undefined" ? require("@heyhabito/amplitude").default : null

export type AmplitudeLogWithSchema = (event: AmplitudeEvent) => void

// unless you have a strong reason not to, this is the function you want
export const logWithSchema = (event: AmplitudeEvent): void => {
  if (amplitude) {
    amplitude.logEvent(event.title, event.payload)
  }
}

// fired whenever a page is viewed
export const registerPageView = (
  page_name: string,
  onComplete: () => void
): void => {
  if (!amplitude) {
    return
  }

  logWithSchema({ title: "viewed_page", payload: { page_name } })

  /**
   * If we were to run any experiments or A/B tests, we could attach them to the
   * user's Amplitude events like so:
   *
   * ```
   * getExperimentVars()
   *  .then(vars => {
   *    amplitude.identify(
   *      new amplitude.Identify().set("habito_experiments", experiments)
   *    )
   *    onComplete()
   *  })
   *  .catch(() => onComplete())
   *  ```
   */

  amplitude.identify(
    new amplitude.Identify()
      .setOnce("landing_page_domain", document.location.hostname)
      .setOnce("landing_page_hash", document.location.hash)
      .setOnce("landing_page_path", document.location.pathname)
      .setOnce("landing_page_url", document.location.href)
      .setOnce("landing_page_title", document.title)
  )

  onComplete()
}

// convenience method
export const registerButtonClick =
  (page_name: string) =>
  (button_name: string, interaction_container?: string): void =>
    logWithSchema({
      title: "clicked_button",
      payload: { button_name, page_name, interaction_container },
    })

// post sign-up screens need to send Amplitude our Habito AccountId so it can
// link the pre- and post- sign-up activity together
export const saveAccountId = (accountId: string): void => {
  if (!amplitude) {
    return
  }

  const identity = new amplitude.Identify()
    .setOnce("account_id", accountId)
    .setOnce("landing_page_domain", document.location.hostname)

  amplitude.setUserId(accountId)
  amplitude.getInstance().identify(identity)
}

export const clearAccountId = (): void => {
  if (!amplitude) {
    return
  }

  amplitude.setUserId(null)
}
