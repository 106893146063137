import React from "react"
import { SentryLoggerDetailed } from "@heyhabito/sentry"
import { Body } from "design-kit"

interface ErrorBoundaryProps {
  sentryLogger: SentryLoggerDetailed
  identifier?: string
  children: React.ReactNode
}

interface ErrorBoundaryState {
  error: Error | null
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  componentDidCatch(error: Error): void {
    let location = "unknown"
    if (window) {
      location = window.location.href
    }
    this.setState({ error })
    this.props.sentryLogger({
      description: `ErrorBoundary: ${error.message}`,
      info: { cause: error, location, identifier: this.props.identifier },
    })
  }

  render(): React.ReactNode {
    return this.state.error ? (
      <Body>Sorry, something went wrong. Please try reloading the page.</Body>
    ) : (
      this.props.children
    )
  }
}
