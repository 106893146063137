const PitchSans = `
  font-family: PitchSans, Courier New, monospace;
  font-style: normal;
  font-weight: normal;
`

const RightGrotesk = `
  font-family: RightGrotesk, Impact, sans-serif;
  font-style: normal;
  font-weight: normal;
`

const Roobert = `
  font-family: Roobert, Trebuchet, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
`

const RoobertBold = `
  font-family: Roobert, Trebuchet, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
`

export const fonts = {
  PitchSans,
  RightGrotesk,
  Roobert,
  RoobertBold,
}
