import React from "react"

type ContextValue = {
  email: string
  accountId: string
  intercomUserHash: string
} | null

const validateToken = async (token: string): Promise<boolean> => {
  const res = await fetch(`/api/sessions`, {
    headers: [
      ["Content-Type", "application/json"],
      ["Authorization", `Bearer ${token}`],
    ],
  })

  return res.status === 200
}

const startNewSession = async (): Promise<string | null> => {
  const res = await fetch(`/api/sessions`, {
    method: "POST",
    headers: [["Content-Type", "application/json"]],
  })

  if (res.status !== 200) {
    return null
  }

  const { token } = await res.json()
  localStorage.setItem("habito/token", token)
  return token
}

const getProfile = async (
  token: string
): Promise<{
  email: string
  accountId: string
  intercomUserHash: string
} | null> => {
  const res = await fetch(`/api/my-account/profile`, {
    headers: [
      ["Content-Type", "application/json"],
      ["Authorization", `Bearer ${token}`],
    ],
  })

  if (res.status !== 200) {
    return null
  }

  return await res.json()
}

const fetchUser = async (): Promise<ContextValue> => {
  const storedToken = localStorage.getItem("habito/token")
  let token: string | null = null

  try {
    // If there's no token, start a new session
    if (!storedToken) {
      token = await startNewSession()
    }
    // If there is, check that it's still valid
    else if (await validateToken(storedToken)) {
      token = storedToken
    }
    // If it wasn't valid, we also need to start a new session
    else {
      token = await startNewSession()
    }

    if (!token) {
      return null
    }

    const profile = await getProfile(token)
    return profile
  } catch (e) {
    return null
  }

  return null
}

export const UserContext = React.createContext<ContextValue>(null)

export const WithUserProvider: React.FunctionComponent = ({ children }) => {
  const [user, setUser] = React.useState<ContextValue>(null)

  React.useEffect(() => {
    fetchUser().then(setUser)
  }, [])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUser = (): ContextValue => React.useContext(UserContext)
