export const vertical = {
  xxl: "96px",
  xl: "64px",
  l: "40px",
  m: "32px",
  s: "24px",
  xs: "16px",
  xxs: "8px",
} as const

export const horizontal = {
  xxl: "64px",
  xl: "40px",
  l: "32px",
  m: "24px",
  s: "16px",
  xs: "8px",
  xxs: "4px",
} as const
