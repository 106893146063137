import React from "react"

import { Base, BaseProps } from "../Base/Base"
import ErrorBoundary from "./ErrorBoundary"
import { WithUserProvider } from "./UserProvider"
import { useSentry } from "@heyhabito/sentry"

export const WrappedBase: React.FunctionComponent<BaseProps> = props => {
  const { logToSentryDetailed } = useSentry()

  return (
    <ErrorBoundary sentryLogger={logToSentryDetailed}>
      <WithUserProvider>
        <Base {...props} />
      </WithUserProvider>
    </ErrorBoundary>
  )
}
