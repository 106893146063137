const white = "#FFFFFF"
const offBlack = "#151515"
const habitoPlus = "#326E5F" // Forest Green

// blue
const action = {
  main: "#006BE6", // True Blue
} as const

const callout = {
  // yellows
  dark: "#FF9600", // Tangerine
  main: "#FFF050", // Sunshine
  // all callout colours
  positive: "#64FAB9", // Seafoam // ~constructive main
  neutral: "#E1E1E1", // ~grey50
  negative: "#FF5757", // Carmine // ~destructive
  pending: "#FF9600", // Tangerine// ~callout dark
  timeline: "#8C50F0", // Electric Grape
  progress: "#FF91DC", // Bubblegum
} as const

// greens
const constructive = {
  dark: "#326E5F", // Forest Green
  main: "#64FAB9", // Seafoam
} as const

// red
const destructive = {
  main: "#FF5757", // Carmine
} as const

const gradientBase = {
  bubblegum: "#FF91DC",
  electricGrape: "#8C50F0",
  mistyViolet: "#96A0FF",
  neonGreen: "#B4F55A",
  seafoam: "#64FAB9",
  sunshine: "#FFF050",
  tangerine: "#FF9600",
  trueBlue: "#006BE6",
} as const

const greyScale = {
  grey100: "#707070",
  grey75: "#A1A1A1",
  grey50: "#E1E1E1",
  grey25: "#F5F5F5",
} as const

export const colours = {
  white,
  offBlack,
  habitoPlus,
  greyScale,
  action,
  constructive,
  callout,
  destructive,
  gradientBase,
} as const
