import { css } from "@emotion/react"
import { CSSInterpolation } from "@emotion/css"

export interface BreakpointValues {
  tablet: number
  desktop: number
}

export const breakpointValues: BreakpointValues = {
  tablet: 768,
  desktop: 1280,
}

export type BreakpointCSS = MediaQueryCSS<BreakpointValues>

type MediaQueryCSS<T> = Record<keyof T, MediaQuerySerializer>

interface MediaQuerySerializer {
  (l: TemplateStringsArray, ...p: Array<CSSInterpolation>): string
  query: string
}

export const createBreakpoints = (sizes: BreakpointValues): BreakpointCSS => {
  const keys = Object.keys(sizes) as Array<keyof BreakpointValues>
  return keys.reduce((acc: BreakpointCSS, label: keyof BreakpointValues) => {
    const query = `(min-width: ${sizes[label]}px)`
    const f = (
      literals: TemplateStringsArray,
      ...placeholders: Array<CSSInterpolation>
    ): string =>
      `@media ${query}{
        ${css(literals, ...placeholders).styles}
      }`

    f.query = query
    acc[label] = f

    return acc
  }, {} as BreakpointCSS)
}

export const breakpoints = createBreakpoints(breakpointValues)
